import * as yup from 'yup'
import { EBookingType } from '../../types/bookings'

export const validationSchema = yup.object().shape({
  status: yup.string().required('Status is required'),
  bookingOrigin: yup.string().required('Booking origin is required'),
  productId: yup
    .string()
    .test('productId-required', 'Product is required', (productId, context) => {
      const { bookingType } = context.parent
      return bookingType === EBookingType.APP_BOOKING || Boolean(productId)
    }),
  vesselId: yup.string().test('vesselId-required', 'Vessel is required', (vesselId, context) => {
    const { bookingType } = context.parent
    return bookingType === EBookingType.APP_BOOKING || Boolean(vesselId)
  }),
  stateRoomCode: yup
    .string()
    .test(
      'stateRoomCode-validation',
      'Stateroom code should only contain 2 alphabetic characters',
      (value) => {
        if (!value) return true
        return /^[A-Za-z]{2}$/.test(value)
      },
    ),
  bookingNumber: yup
    .number()
    .max(9999999, 'Booking number should be less or equal than 7 digits')
    .required('Booking number is required')
    .typeError('Booking number must be a number'),
  comment: yup
    .string()
    .test(
      'comment-required',
      'Comment is required when other stateroom option is selected',
      (comment, context) => {
        const { stateRoomCategory } = context.parent
        return (
          stateRoomCategory !== 'Other (Add Comment)' ||
          (stateRoomCategory === 'Other (Add Comment)' && comment !== undefined)
        )
      },
    ),
})
