import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import Divider from '@mui/material/Divider'
import DialogContent from '@mui/material/DialogContent'
import Grid from '@mui/material/Grid'
import { Controller, useForm } from 'react-hook-form'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import moment from 'moment'
import Stack from '@mui/material/Stack'
import { Card, CardContent, DialogActions, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { IAppointmentCallbackFormProps, IMarkAppointmentAsCallBackForm } from './types'
import { validationSchema } from './validation'
import { minDateCallback, maxDateCallback } from '../../utils/date'

// Mover la definición del componente fuera del render
const CustomPickersDay = (props: PickersDayProps<moment.Moment>) => (
  <PickersDay
    {...props}
    sx={{
      '&.MuiButtonBase-root.MuiPickersDay-root.Mui-selected.Mui-disabled': {
        color: 'rgba(255, 255, 255, 0.5)',
      },
    }}
  />
)

const AppointmentCallbackForm: React.FC<IAppointmentCallbackFormProps> = ({
  isOpen,
  agents,
  appointment,
  cruiseDates,
  onClose,
  onSubmit,
}) => {
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(
    appointment.callback?.date ? moment.utc(appointment.callback.date) : null,
  )
  const [isHourInputDisabled, setIsHourInputDisabled] = useState(!!appointment.callback?.time)

  const [minDate, setMinDate] = useState('')
  const [maxDate, setMaxDate] = useState('')

  useEffect(() => {
    if (cruiseDates) {
      setMinDate(minDateCallback(cruiseDates.start_date))
      setMaxDate(maxDateCallback(cruiseDates.end_date))
    }
  }, [cruiseDates])

  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm<IMarkAppointmentAsCallBackForm>({
    defaultValues: {
      agent_id: appointment.agent?.agent_id,
      callback_date: appointment.callback?.date
        ? moment.utc(appointment.callback.date).format('MM/DD/YYYY')
        : undefined,
      callback_time: appointment.callback?.time ? appointment.callback.time : '',
      comment: appointment.comment ?? '',
    },
    resolver: yupResolver(validationSchema),
  })

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <DialogContent>
          <Stack direction="row" justifyContent="flex-end" alignItems="center">
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Controller
                    control={control}
                    name="callback_date"
                    render={({ field: { onChange, value, ...field } }) => (
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          {...field}
                          label="Date"
                          minDate={minDate ? moment(minDate) : undefined}
                          maxDate={maxDate ? moment(maxDate) : undefined}
                          slots={{
                            textField: TextField,
                            day: CustomPickersDay
                          }}
                          slotProps={{
                            textField: {
                              error: !!errors.callback_date,
                              helperText: errors?.callback_date?.message as string
                            }
                          }}
                          value={selectedDate}
                          onChange={(newValue) => {
                            onChange(newValue)
                            setIsHourInputDisabled(false)
                            setValue('callback_time', '')
                            setSelectedDate(newValue)
                          }}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  {isHourInputDisabled ? (
                    <Controller
                      control={control}
                      name="callback_time"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          sx={{ mt: 0 }}
                          margin="normal"
                          fullWidth
                          label="Hour"
                          error={!!errors.callback_time}
                          helperText={errors?.callback_time?.message as string}
                          disabled>
                          {appointment.callback?.time}
                        </TextField>
                      )}
                    />
                  ) : (
                    <Controller
                      control={control}
                      name="callback_time"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          sx={{ mt: 0 }}
                          margin="normal"
                          fullWidth
                          label="Hour"
                          error={!!errors.callback_time}
                          helperText={errors?.callback_time?.message as string}
                          placeholder="HH:mm A"
                          onChange={(e) => {
                            if (
                              (e.nativeEvent as InputEvent).inputType === 'deleteContentBackward'
                            ) {
                              field.onChange(e.target.value)
                            } else {
                              // Remove non-alphanumeric characters
                              const cleanedValue = e.target.value.replace(/[^0-9a-zA-Z]/g, '')

                              // Format the time as hh:mm A
                              const formattedValue = cleanedValue.replace(
                                /(\d{2})(\d{2})([aApPmM]{0,2})/,
                                (match, hours, minutes, AA) => {
                                  const upperCaseAA = AA.toUpperCase()
                                  return `${hours}:${minutes} ${upperCaseAA}`
                                },
                              )
                              field.onChange(formattedValue)
                            }
                          }}
                        />
                      )}
                    />
                  )}
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    control={control}
                    name="agent_id"
                    render={({ field }) => (
                      <TextField {...field} label="Agent" fullWidth select>
                        <MenuItem value="no-agent">No Agent</MenuItem>
                        {agents.map((agent) => (
                          <MenuItem key={`agent-${agent.id}`} value={agent.id}>
                            {`${agent.first_name} ${agent.last_name}`}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Grid>
              </Grid>
              <Divider sx={{ margin: '1rem 0' }} />
              <Controller
                control={control}
                name="comment"
                render={({ field }) => (
                  <TextField
                    fullWidth
                    multiline
                    maxRows={3}
                    minRows={3}
                    {...field}
                    placeholder="Comments"
                  />
                )}
              />
            </CardContent>
          </Card>
        </DialogContent>
        <DialogActions>
          <Stack
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            columnGap={1}
            sx={{ mb: '1.25rem', mr: 2 }}>
            <Button type="submit" variant="contained" color="success">
              Save
            </Button>
          </Stack>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default AppointmentCallbackForm
