export interface IBookingPayload {
  appointmentId?: number
  destination_id?: number
  ship_id?: number
  booking_number: string
  booking_name?: string
  amount?: number
  stateroom_category?: string
  stateroom_code?: string
  sail_date?: string
  status: string
  agent_id?: string
  comment?: string
  origin?: string
  non_refundable?: boolean
  used_visa_card?: boolean
  next_cruise_bonus?: boolean
  booking_type?: string
  deposit_amount?: number
  deposit_currency?: string
  pending_items?: boolean
  bookingId?: number
  savings_type?: string
}

export enum EBookingType {
  BOOK_NOW = 'Book Now',
  BOOK_LATER = 'Book Later',
  APP_BOOKING = 'App Booking',
}
