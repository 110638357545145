import moment from 'moment'
import { Select, MenuItem, SelectChangeEvent } from '@mui/material'
import { IMonthYearSelectorProps } from './types'

const MonthYearSelector: React.FC<IMonthYearSelectorProps> = ({ onChange, initialValue }) => {
  const value = initialValue ? `${initialValue.month},${initialValue.year}` : ''

  const today = moment()
  const options = []
  for (let i = 0; i < 12; i += 1) {
    const currentMonth = today.clone().subtract(i, 'months')
    options.push(
      <MenuItem key={`option-${i}`} value={currentMonth.format('M,YYYY')}>
        {currentMonth.format('MMMM YYYY')}
      </MenuItem>,
    )
  }

  const nextMonth = today.add(1, 'month')
  options.unshift(
    <MenuItem value={nextMonth.format('M,YYYY')}>{nextMonth.format('MMMM YYYY')}</MenuItem>,
  )

  const handleOnChange = (event: SelectChangeEvent) => {
    const values = event.target.value.split(',')
    onChange({ month: +values[0], year: +values[1] })
  }

  return (
    <Select value={value} onChange={handleOnChange}>
      {options}
    </Select>
  )
}

export default MonthYearSelector
