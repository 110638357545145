import { forwardRef } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { PrintableQuoteProps } from './types'

const PrintableQuote = forwardRef<HTMLDivElement, PrintableQuoteProps>((props, ref) => {
  const isSafari =
    /^((?!chrome|android).)*apple/i.test(navigator.vendor) &&
    /\bSafari\b/i.test(navigator.userAgent)
  const reportRow = (
    label: string,
    value: string,
    additionalLabel?: string,
    additionalValue?: string,
    noBorderBottom?: boolean,
    smallLabel?: boolean,
    labelTwoLines?: boolean,
  ) => (
    <Box
      sx={{
        borderBottom: noBorderBottom ? 'none' : '1px solid black',
        alignItems: 'center',
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        position: 'relative',
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: '10px 0',
          paddingLeft: '10px',
          width: '40%',
        }}>
        <Typography
          fontSize={smallLabel ? 13 : 14}
          fontFamily="NeutraText-Bold"
          lineHeight="14px"
          sx={{ width: labelTwoLines ? '80%' : '100%' }}>
          {label}
        </Typography>
        {additionalLabel && <Typography fontFamily="NeutraText-Bold">{additionalLabel}</Typography>}
      </Box>
      <Box
        sx={{
          background: 'black',
          width: '1px',
          height: '100%',
          position: 'absolute',
          left: 'calc(38% + 10px)',
        }}
      />
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flex: 1,
          justifyContent: 'space-between',
          padding: '5px 0',
          position: 'relative',
        }}>
        <Typography
          fontFamily="NeutraText-Book"
          textTransform="uppercase"
          textAlign="center"
          fontSize={14}
          lineHeight="14px"
          sx={{ padding: '0 10px', width: additionalValue ? '64%' : '100%' }}>
          {value}
        </Typography>
        {additionalValue && (
          <>
            <Box
              sx={{
                background: 'black',
                width: '1px',
                height: '100%',
                position: 'absolute',
                left: '64%',
              }}
            />
            <Typography
              fontFamily="NeutraText-Book"
              textAlign="center"
              fontSize={13}
              lineHeight="13px"
              sx={{ padding: '0 5px', width: '36%' }}>
              {additionalValue}
            </Typography>
          </>
        )}
      </Box>
    </Box>
  )

  return (
    <Box
      ref={ref}
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        position: 'relative',
        margin: !isSafari ? '24px' : 0,
      }}>
      {/* White space for header */}
      <Box sx={{ height: !isSafari ? '445px' : '410px', width: '100%' }} />
      {/* Table */}
      <Box
        style={{
          border: '1px solid black',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          margin: '0 auto',
          width: !isSafari ? '430px' : '400px',
        }}>
        <Typography
          color="white"
          fontFamily="NeutraText-Bold"
          fontSize={18}
          padding="10px"
          width="100%"
          sx={{
            backgroundColor: 'black',
          }}>
          Itinerary & Promotional Information
        </Typography>
        {reportRow('SHIP', props.formData.vesselValue ?? '')}
        {props.formData.sailDate &&
          reportRow('SAIL DATE', props.formData.sailDate.format('MMMM DD, YYYY') ?? '')}
        {reportRow('DESTINATION', props.formData.product ?? '')}
        {props.formData.stateroomCategory &&
          reportRow('CABIN CATEGORY', props.formData.stateroomCategory)}
        {reportRow(
          'TOTAL PRICE',
          `${props.formData.currency ?? '$'} ${props.formData.amount}`,
          '',
          '',
          !props.formData.savingType && !props.formData.exclusiveOnboardCredit,
        )}
        {props.formData.savingType &&
          reportRow(
            'PROMOTION',
            props.formData.savingType ?? '',
            '',
            props.formData.nonRefundable ? 'Non Refundable' : '',
            !props.formData.exclusiveOnboardCredit,
          )}
        {props.formData.exclusiveOnboardCredit &&
          reportRow(
            'EXCLUSIVE ONBOARD CREDIT',
            `$ ${props.formData.exclusiveOnboardCredit}`,
            '(per stateroom)',
            '',
            true,
            true,
            true,
          )}
      </Box>
    </Box>
  )
})

export default PrintableQuote
