import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { yupResolver } from '@hookform/resolvers/yup'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Chip from '@mui/material/Chip'
import FormHelperText from '@mui/material/FormHelperText'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import moment from 'moment'
import { validationSchema } from './validations'
import { IEventFormProps } from './types'
import { IEvent } from '../../types/events'

const EventForm = (props: IEventFormProps) => {
  const { event, isOpen, isLoading, agents, destinations, onAccept, onReject } = props
  const isEdit = !!event
  const title = isEdit ? 'Edit Event' : 'New Event'

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm<IEvent>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      ...event,
      name: event ? event.name : [],
      presenterId: event ? event.presenterId : '',
    },
  })

  const [selectedNames, setSelectedNames] = useState<string[]>(event?.name ?? [])
  const [date, setDate] = useState(
    event ? moment(event.date, 'dddd, MMMM DD, YYYY').format('YYYY-MM-DD') : '',
  )

  const onDeleteName = (value: string) => {
    const newNames = selectedNames.filter((name) => name !== value)
    setSelectedNames(newNames)
  }

  const handleChangeName = (onChangeEvent: SelectChangeEvent<typeof selectedNames>) => {
    const {
      target: { value },
    } = onChangeEvent
    setSelectedNames(typeof value === 'string' ? value.split(',') : value)
  }

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <Box component="form" onSubmit={handleSubmit(onAccept)} noValidate>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Controller
            control={control}
            name="name"
            render={({ field: { value, onChange, ...field } }) => (
              <FormControl fullWidth sx={{ marginTop: 1 }}>
                <InputLabel id="event-multiple-select-name">Products</InputLabel>
                <Select
                  {...field}
                  label="Products"
                  id="event-multiple-select-name"
                  multiple
                  error={!!errors.name}
                  fullWidth
                  value={selectedNames}
                  onChange={(e) => {
                    handleChangeName(e)
                    onChange(
                      typeof e.target.value === 'string'
                        ? e.target.value.split(',')
                        : e.target.value,
                    )
                  }}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((selectedValue) => (
                        <Chip
                          key={selectedValue}
                          label={selectedValue}
                          onDelete={() => {
                            onDeleteName(selectedValue)
                            const newNames = selected.filter((name) => name !== selectedValue)
                            onChange(newNames)
                          }}
                          onMouseDown={(e) => e.stopPropagation()}
                        />
                      ))}
                    </Box>
                  )}>
                  {destinations.map((destination) => (
                    <MenuItem key={destination.name} value={destination.name}>
                      {destination.name}
                    </MenuItem>
                  ))}
                </Select>
                {!!errors.name && (
                  <FormHelperText sx={{ color: 'error.main', marginLeft: '16px !important' }}>
                    {errors.name.message as string}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          />
          <Controller
            name="date"
            control={control}
            render={({ field: { onChange } }) => (
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  label="Date"
                  format="MM/DD/YYYY"
                  value={date ? moment(date) : null}
                  onChange={(e) => {
                    onChange(e)
                    setDate(e ? e.format('YYYY-MM-DD') : '')
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      sx: { marginTop: 2 },
                      error: !!errors.date,
                      helperText: errors?.date?.message as string
                    }
                  }}
                />
              </LocalizationProvider>
            )}
          />
          <Controller
            control={control}
            name="presenterId"
            render={({ field }) => (
              <TextField
                {...field}
                select
                margin="normal"
                fullWidth
                id="presenter"
                label="Event Presenter"
                error={!!errors.presenterId}
                helperText={errors?.presenterId?.message as string}>
                {agents.map((agent) => (
                  <MenuItem key={`key-port-${agent.username}`} value={agent.id}>
                    {`${agent.first_name} ${agent.last_name}`}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          <TextField
            label="Description"
            margin="dense"
            multiline
            fullWidth
            rows={2}
            {...register('description', { required: false })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onReject}>Cancel</Button>
          <LoadingButton type="submit" loading={isLoading}>
            Save
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default EventForm
