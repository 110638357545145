import { IDestinationSimple } from './destinations'
import { IShipSimple } from './ships'
import { ICruiseAgent } from './users'
import { IOtherForm } from '../components/AppointmentOtherForm/types'
import type { IMarkAppointmentAsCallBackForm } from '../components/AppointmentCallbackForm/types'

export interface IAppointmentQueueResponse {
  success: boolean
  appointment_id: number
  already_in_line: number
  appointmentDate: string
  appointment_data: string
  status: number
  message: string
}

export interface IAppointmentAgentPayload {
  appointmentId: number
  agent: ICruiseAgent
}

export interface IGetAppointmentsPayload {
  cruise_id: number
  queue_id: number
  status: string
  agent_id?: string
  type?: string
  keywords?: string
  keywordsDate?: string
  sort_by?: string
  sort_dir?: string
  deleted?: number
  filter_type?: string
  filter_status?: string
  has_pending_items?: boolean
}

export interface IGetAppointmentsResponse {
  rows: IAppointment[]
  people: IAppointmentPeople
}

export interface IAppointmentPeople {
  pending: number
  queued: number
  cruise_later_counter: number
  callback: number
  inprogress: number
  finished: number
  deleted: number
  quotes: number
  bookings: number
  cruise_later: number
  bookings_draft: number
  bookings_offered: number
  bookings_confirmed: number
  bookings_cancelled: number
  others: number
  others_noshow: number
  others_notrelated: number
  others_existingbooking: number
}

export interface IAppointment {
  agent?: IAppointmentAgent
  agent_id?: string
  appointment_id?: number
  bookings?: IAppointmentBooking[]
  cards?: IAppointmentCard[]
  callback?: IAppointmentCallback
  callback_date?: string
  callback_time?: string
  card_id?: string
  comment?: string
  cruise_id?: number
  cruise_port_id?: number
  events?: number
  first_name?: string
  has_pending_items?: boolean
  inPersonCallback?: boolean
  isDeleted?: number
  last_action_comment?: string
  last_action_date?: string
  last_name?: string
  mailDrop?: number
  order_in_line?: number
  others?: IAppointmentOthers[]
  person?: IAppointmentPerson
  port?: IAppointmentPort
  queue_id?: number
  queued_on?: string
  quotes?: IAppointmentQuote[]
  recorded_on?: string
  room?: number
  serverTime?: string
  status?: string
  type?: string
  user_logged_id?: string
  visitTime?: number
  logs?: ILog[]
  other_guests?: IAppointmentPerson[]
}

export interface IAppointmentPort {
  port_id: number
  name: string
  isDeleted: number
  timezone: string
  timezoneOffset: string
  cruise_port_id: number
  arrival_date: string
}

export interface IAppointmentAgent {
  agent_id?: string
  first_name?: string
  last_name?: string
  username?: string
}

export interface IAppointmentCallback {
  date: string
  time: string
}

export interface IAppointmentPerson {
  person_id: number
  first_name: string
  last_name: string
  email?: string
  phoneNumber?: string
  room: number
  card_id: string
  validated: number
  cruiseBookingId?: string
  guestId?: string
  loyaltyLevel?: string
  loyaltyNumber?: string
  nationality?: string
  gateway?: string
  isCasino?: boolean
  bookingOfficeCode?: string
  stateCode?: string
  bookingChannel?: string
  nextCruiseFlag?: string
  rciCardHolderFlag?: string
  celCardHolderFlag?: string
  pastOnboardFlag?: string
  pastOnboardBkngCnt?: string
  casinoLoyaltyTier?: string
  agentName?: string
  agentPhoneNbr?: string
  rciCompletedCruises?: number
}

export interface IAppointmentOthers {
  stateroom_number?: number
  other_id?: number
  person_id?: number
  appointment_id?: number
  result?: string
  agent_id?: string
  other_on?: string
  comment?: string
  other_comment?: string
  agent?: IAppointmentAgent
  isFromThisAppointment?: boolean
}

export interface IAppointmentCard {
  card_id?: number
  stateroom_number?: number
  person: IPerson
  appointment_id?: number
  transferred_to_folio_account?: boolean
  card_comment?: string
  card_status?: string
  card_status_date?: string
  agent?: IAgent
}

export interface IAppointmentBooking {
  booking_type: string
  stateroom_number?: number
  booking_id?: number
  person_id?: number
  appointment_id?: number
  destination_id?: number
  ship_id?: number
  booking_status?: string
  booking_status_date?: string
  booking_number?: string
  booking_non_refundable?: number
  booking_used_visa_card?: number
  booking_next_cruise_bonus?: number
  booking_amount?: string
  booking_status_future?: string
  booking_number_future?: number
  booking_stateroom_cat_future?: string
  booking_GTR_future?: string
  booking_departure_date_future?: string
  group_shell_number?: string
  agent_id?: string
  booked_date_future?: string
  comment?: string
  booking_origin?: string
  booking_name?: string
  mcc_ref?: string
  non_refundable?: number
  pending_items?: number
  destination?: IDestinationSimple
  ship?: IShipSimple
  agent?: IAgent
  deposit_amount?: number
  deposit_currency?: string
  booking_stateroom_category?: null
  booking_stateroom_code?: null
  booking_saildate?: string
  booking_comment?: string
  booking_pending_items?: number
  isFromThisAppointment?: boolean
  booking_travel_agency?: boolean
  booking_casino?: boolean
  booking_reasonWhy?: string
  savings_type?: string
}

export interface IAgent {
  agent_id: string
  first_name: string
  last_name: string
  username: string
}

export interface IPerson {
  person_id: number
  name: string
  room: number
}

export interface IAppointmentQuote {
  agent?: IAppointmentAgent
  agent_id?: string
  appointment_id?: number
  comment?: string
  destination?: IDestinationSimple
  destination_id?: number
  group_shell_number?: string
  isFromThisAppointment?: boolean
  has_quote_attachment: boolean
  non_refundable?: number
  person_id?: number
  quote_comment?: string
  quote_id?: number
  quote_non_refundable?: number
  quote_status?: string
  quote_status_date?: string
  quoted_amount?: number
  quoted_departure_date_future?: string
  quoted_sail_date: string
  quoted_stateroom_category?: string
  ship: IShipSimple
  ship_id?: number
  currency?: string
  stateroom_number?: number
  no_book_reason?: string
  exclusive_onboard_credit?: number
  next_cruise_bonus?: number
  bonus_savings_value?: number
  bonus_value?: number
  saving_type?: string
}

export interface IMarkAppointmentAsCallBackPayload extends IMarkAppointmentAsCallBackForm {
  appointment_id: number
}

export interface ISocketGenericResponse {
  message: string
  appointmentId: number
  userId: string
}

export interface ISocketAgentAssignedResponse extends ISocketGenericResponse {
  agentId: string
}

export enum EStatusAppointment {
  IN_PROGRESS = 'INPROGRESS',
  FINISHED = 'FINISHED',
  DELETED = 'DELETED',
  PENDING = 'PENDING',
}
export interface IVisitTimePayload {
  id: number
  time: number
}

export interface IChangePortPayload {
  id: number
  portId: number
}

export interface IAppointmentStatusUpdatePayload {
  appointmentId: number
  status: EStatusAppointment
}

export interface IAppointmentSimple {
  appointment_id: number
  order: number
}

export interface ILog {
  log_id: number
  recorded_on: string
  type: string
  comment: string
  event_type: string
  person: IPersonLog
  user: IUserWithName
}

export interface IPersonLog {
  person_first_name: string
  person_last_name: string
}

export interface IUserWithName {
  user_id?: string
  first_name?: string
  last_name?: string
  username?: string
}

export interface IAppointmentOtherPayload extends IOtherForm {
  appointmentId: number
}

export interface IAppointmentOtherEditPayload {
  appointmentId: number
  otherId: number
}

export interface IAppointmentOtherResponse {
  appointment: IAppointment
  success: boolean
  message: string
}
export enum EQuoteStatus {
  DONE = 'DONE',
}

export enum EBookingStatus {
  DRAFT = 'DRAFT',
  OFFERED = 'OFFERED',
  CONFIRMED = 'CONFIRMED',
  CANCELLED = 'CANCELLED',
}

export enum EOtherResult {
  NOTRELATED = 'NOTRELATED',
  EXISTINGBOOKING = 'EXISTINGBOOKING',
  NOSHOW = 'NOSHOW',
  CREDIT_CARD_QUESTION_APPLICATION = 'CREDITCARDQUESTIONAPPLICATION',
  NEXTCRUISE_PROGRAM = 'NEXTCRUISEPROGRAM',
  GENERAL_INFORMATION = 'GENERALINFORMATION',
  LOYALTY_QUESTION = 'LOYALTYQUESTION',
  COMPLAINT = 'COMPLAINT',
}

export enum EAppointmentDBType {
  QUEUED = 'QUEUED',
  QUEUE = 'QUEUE',
  CALLBACK = 'CALLBACK',
  CRUISELATER = 'CRUISELATER',
}

export enum EAppointmentStatus {
  PENDING = 'PENDING',
  INPROGRESS = 'INPROGRESS',
  FINISHED = 'FINISHED',
  DELETED = 'DELETED',
}

export enum EAppointmentMainTab {
  INLINE = 'In Line',
  INPROGRESS = 'In Progress',
  FINALIZED = 'Finalized',
  DELETED = 'Deleted Appointments',
}

export enum EAppointmentInLineTab {
  ALL = 'All',
  INLINE = 'In Line',
  CALLBACK = 'Appointment',
  CRUISELATER = 'App Bookings',
}

export enum EAppointmentDetailsTab {
  CARD = 'Card',
  BOOKINGS = 'Bookings',
  QUOTES = 'Quotes',
  OTHERS = 'Others',
  NOSHOW = 'No Show',
  EVENTS = 'Events',
  MAILDROP = 'Mail Drop',
  LOG = 'Log',
}
