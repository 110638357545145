import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from './baseQuery'
import { IReport } from '../../pages/Settings/VoyageReport/types'
import { ICardReport } from '../../pages/Settings/VoyageCardReport/types'
import { IGetExcelResponse } from '../../types/reports'

export const reportsApi = createApi({
  reducerPath: 'reportsApi',
  baseQuery,
  endpoints: (builder) => ({
    getCruiseReport: builder.query<IReport[], number>({
      query: (cruiseId) => ({
        url: `/reports/cruise/${cruiseId}`,
        method: 'GET',
      }),
    }),
    getCruiseCardReport: builder.query<ICardReport[], number>({
      query: (cruiseId) => ({
        url: `/reports/cruise/${cruiseId}/cards`,
        method: 'GET',
      }),
    }),
    getExcelReport: builder.query<IGetExcelResponse, number>({
      query: (cruiseId) => ({
        url: `/reports/cruise/${cruiseId}/excel`,
        method: 'GET',
      }),
    }),
    getExcelCardReport: builder.query<IGetExcelResponse, number>({
      query: (cruiseId) => ({
        url: `/reports/cruise/${cruiseId}/cards/excel`,
        method: 'GET',
      }),
    }),
  }),
})

export const {
  useGetCruiseReportQuery,
  useGetCruiseCardReportQuery,
  useLazyGetExcelReportQuery,
  useLazyGetExcelCardReportQuery,
} = reportsApi
