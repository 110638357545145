import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from './baseQuery'
import { IDeleteCasinoPayload, IGetCasinoRecordsResponse } from '../../types/casino'
import { IGenericResponse } from '../../types/generic'

export const casinoApi = createApi({
  reducerPath: 'casinoApi',
  baseQuery,
  endpoints: (builder) => ({
    get: builder.query<IGetCasinoRecordsResponse, number>({
      query: (id) => ({
        url: `/casino/${id}`,
        method: 'GET',
      }),
    }),
    create: builder.mutation<IGenericResponse, { file: (File | string)[] }>({
      query: (payload) => ({
        url: `/casino`,
        method: 'POST',
        body: payload,
      }),
    }),
    remove: builder.mutation<IGenericResponse, IDeleteCasinoPayload>({
      query: (payload) => ({
        url: `/casino`,
        method: 'DELETE',
        body: payload,
      }),
    }),
  }),
})

export const { useGetQuery, useCreateMutation, useRemoveMutation } = casinoApi
